import { InjectedConnector } from "@web3-react/injected-connector";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletConnectV2Connector } from "./walletconnectV2Connector";

export const injected = new InjectedConnector({
  supportedChainIds: [56], // Pass multiple chain id like - [1,3,4,56,80001]
});

const RPC_URL = 'https://bsc-dataseed.binance.org'; // rpc url of following chain

export const walletConnect = new WalletConnectV2Connector({
  projectId: "6b63a429a76c4699c8e90bd36a1c93b0",
  showQrModal: true,
  chains: [56],
  rpcMap: {
    56: RPC_URL,
  },
});

// export const walletConnect = new WalletConnectConnector({
//   rpc: { 56: RPC_URL}, // pass multiple rpc for chain like {chainid1 :chainRPCUrl_1, chainid2:chainRpcUrl2}
//   drcode: true,
//   chainId: 56
// });
