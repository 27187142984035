import React, { useState } from "react";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import useMetaMask from "../wallet/hook";

function Navbar() {
  const { connect, disconnect,account,isActive, walletModal, handleWalletModal } = useMetaMask();
  const [burger, setBurger] = useState(false);

  const changeBurger = async (value) => {
    window.scrollTo(0, 0);
    if (burger == false) {
      setBurger(true);
    } else {
      setBurger(false);
    }
    console.log("burger", burger);
  };

  return (
    <div className={` ${burger ? "activeBurger" : ""}`}>
      <nav className="navbar navbar-default navbar-trans navbar-expand-lg">
        <div className="container">
          <Link className="navbar-brand text-brand" to="/">
            <img src="images/logo.svg" alt="logo" />
          </Link>
          <div
            className="navbar-collapse collapse justify-content-left"
            id="navbarDefault"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/#" title="Trade">
                  {" "}
                  Stack{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#" title="Earn">
                  {" "}
                  Earn{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#" title="Project">
                  {" "}
                  Project{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#" title="Audit">
                  {" "}
                  Audit{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#" title="Analytics">
                  {" "}
                  Analytics{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#" title="Event">
                  {" "}
                  Event{" "}
                </a>
              </li>
              <li className="nav-item dropdown">
                {" "}
                <a
                  className="nav-link dropdown-toggle down-arrow"
                  href="/#"
                  title="Kala
            Project"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  DMC Project
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 6.26205C5.19577 6.26239 5.38968 6.22404 5.57059 6.14919C5.75149 6.07435 5.91581 5.96449 6.05412 5.82594L10 1.87932L8.94588 0.825195L5 4.77107L1.05412 0.825195L0 1.87932L3.94588 5.82519C4.08412 5.96388 4.24842 6.07388 4.42933 6.14885C4.61023 6.22382 4.80418 6.26229 5 6.26205Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <div className="dropdown-menu__outer">
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a className="dropdown-item" href="/#" title="">
                      Project 1
                    </a>
                    <a className="dropdown-item" href="/#" title="">
                      Project 2
                    </a>
                    <a className="dropdown-item" href="/#" title="">
                      Project 2
                    </a>
                    <a className="dropdown-item" href="/#" title="">
                      Project 2
                    </a>
                    <a className="dropdown-item" href="/#" title="">
                      Project 2
                    </a>
                    <a className="dropdown-item" href="/#" title="">
                      Project 2
                    </a>
                    <a className="dropdown-item" href="/#" title="">
                      Project 2
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="right-side">
            <ul>
              {isActive ? (
                <li>
                  {account == null ? (
                    <a
                      // href="/#"
                      className="sr-btn main_btn"
                      // data-toggle="modal"
                      // data-target="#myModal"
                      onClick={() => handleWalletModal(true)}
                    >
                      Connect Wallet
                    </a>
                  ) : (
                    <div className="disconnect-container">
                      <span>
                        {account.slice(0, 2).concat(`...${account.slice(-4)}`)}
                      </span>
                      <div
                        className="sr-btn main_btn"
                        onClick={() => disconnect()}
                      >
                        Disconnect Wallet
                      </div>
                    </div>
                  )}
                </li>
              ) : (
                <li>
                  <a
                    // href="/#"
                    className="sr-btn main_btn"
                    // data-toggle="modal"
                    // data-target="#myModal"
                    onClick={() => handleWalletModal(true)}
                  >
                    Connect Wallet
                  </a>
                </li>
              )}

              <div
                className={`modal main__modal ${walletModal ? "show" : ""}`}
                id="walletConnectModal"
              >
                <div
                  className="modal-bg"
                  onClick={() => handleWalletModal(false)}
                ></div>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Connect Wallet</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={() => handleWalletModal(false)}
                      >
                        <svg
                          width="34"
                          height="35"
                          viewBox="0 0 34 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="17"
                            cy="17.9661"
                            r="16"
                            stroke="#E5E5E5"
                            strokeWidth="2"
                          />
                          <path
                            d="M21.5342 13.4326L12.4675 22.4993"
                            stroke="white"
                            strokeWidth="2"
                          />
                          <path
                            d="M12.4658 13.4326L21.5325 22.4993"
                            stroke="white"
                            strokeWidth="2"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="modal-body">
                      <div
                        className="btn-connect"
                        onClick={() => connect("metaMask")}
                      >
                        <h1 className="connect">Metamask</h1>
                        <img src="images/meta.png" />
                      </div>
                      <div
                        className="btn-connect"
                        onClick={() => connect("walletConnect")}
                      >
                        <h1 className="connect">Connect Wallet</h1>
                        <img src="images/wallet-connect.png" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            {/*<div*/}
            {/*  className={`burger ${burger ? "active" : ""}`}*/}
            {/*  onClick={() => changeBurger()}*/}
            {/*>*/}
            {/*  <span></span>*/}
            {/*  <span></span>*/}
            {/*</div>*/}
          </div>
          {/*<button*/}
          {/*  className="navbar-toggler collapsed"*/}
          {/*  type="button"*/}
          {/*  data-toggle="collapse"*/}
          {/*  data-target="#navbarDefault"*/}
          {/*  aria-controls="navbarDefault"*/}
          {/*  aria-expanded="false"*/}
          {/*  aria-label="Toggle navigation"*/}
          {/*>*/}
          {/*  <span></span>*/}
          {/*  <span></span>*/}
          {/*  <span></span>*/}
          {/*</button>*/}
        </div>
      </nav>
      <div className="burger-bg"></div>
    </div>
  );
}

export default Navbar;
